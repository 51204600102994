import React, { useEffect, useState } from 'react';
// @mui
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
// components
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import { useSelector } from '../../../redux/store';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function LanguagePopover({ sx = {}, CustomBtn, PopoverProps = {} }) {
  const { allLangs, currentLang, onChangeLang } = useLocales();

  const { siteSettings } = useSelector((x) => x.lookup);

  const [customLangs, setCustomLangs] = useState([]);

  const [open, setOpen] = useState(null);

  const [selectedLang, setSelectedLang] = useState(currentLang);

  const domainLocale = process.env.REACT_APP_LOCALE;

  const langStorage = localStorage.getItem('i18nextLng');

  useEffect(() => {
    // setCustomLangs(allLangs.filter((f) => f.value === 'en' || f.value === domainLocale));
    if (siteSettings?.languages?.length > 0) {
      const matchingLangs = allLangs.filter((obj1) =>
        siteSettings?.languages.some((obj2) => obj1.value === obj2.locale)
      );

      if (matchingLangs) {
        setCustomLangs(matchingLangs);

        if (matchingLangs.findIndex((ml) => ml.value === langStorage) === -1) {
          handleChangeLang(matchingLangs.find((f) => f.value === domainLocale)?.value);
          console.log('matching');
        }
      } else {
        console.log('No Matching languages');
      }
    }
  }, [siteSettings?.languages]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLang = (newLang) => {
    // localStorage.setItem('i18nextLng', newLang);
    onChangeLang(newLang);
    handleClose();
    window.location.reload();
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={(e) => handleOpen(e)}>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
          {CustomBtn ? (
            React.cloneElement(CustomBtn, { title: selectedLang?.label })
          ) : (
            <Image disabledEffect src={selectedLang.icon} alt={selectedLang.label} sx={{ width: 28 }} />
            // <Button
            //   variant={'text'}
            //   // onClick={handleOpen}
            //   startIcon={<Image disabledEffect src={selectedLang.icon} alt={selectedLang.label} sx={{ width: 28 }} />}
            //   sx={sx}
            //   size="small"
            // >
            //   <Typography fontWeight={700} sx={{ fontSize: '12px' }}>
            //     {selectedLang.value?.toUpperCase()}
            //   </Typography>
            // </Button>
          )}
        </Box>
        {/* <Iconify icon={'iconamoon:arrow-down-2-fill'} sx={{ fontSize: '20px', height: '20px', width: '20px' }} /> */}
      </Box>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          width: 200,
          background: '#c8c8c8',
          '& .MuiMenuItem-root': {
            fontSize: '.26rem',
            borderRadius: 0.75,
            color: '#333',
            backgroundColor: '#c8c8c8 !important',
          },
        }}
        {...PopoverProps}
      >
        <Stack spacing={0.75}>
          {customLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                handleChangeLang(option.value);
                setSelectedLang(option);
              }}
            >
              <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, minWidth: 28, mr: 2 }} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
