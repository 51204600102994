// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const my = {
  game_history: `ဂိမ်းသမိုင်း`,
  customer_service: `ဧည့်ဝန်ဆောင်မှု`,
  name: `နာမည်`,
  time: `အချိန်`,
  bet_amount: `လောင်းကြေးပမာဏ`,
  login: `လော့ဂ်အင်`,
  register: `မှတ်ပုံတင်ပါ။`,
  captcha: `CAPTCHA`,
  forgot_password: `စကားဝှက်မေ့နေပါသလား`,
  minor_note: `ဤဆိုက်ကို ဝင်ကြည့်ရန် သင်သည် အသက် 18 နှစ်ကျော်ပြီး သဘောတူကြောင်း သေချာပါစေ။`,
  terms_of_service: `ဝန်ဆောင်မှုစည်းမျဉ်းများ`,
  sign_up: `ဆိုင်းအပ်`,
  username: `အသုံးပြုသူအမည်`,
  email: `အီးမေးလ်`,
  full_name: `နာမည်အပြည့်အစုံ`,
  confirm_18: `ကျွန်ုပ်သည် အသက် 18 နှစ်ဖြစ်ပြီး ဖတ်ပြီးကြောင်း အတည်ပြုပါသည်။`,
  share: `မျှဝေပါ။`,
  deposit: `အပ်ငွေ`,
  bonus: `ဘောနပ်စ်`,
  app: `App`,
  load_more: `နောက်ထပ် တင်ပါ။`,
  details: `အသေးစိတ်`,
  helps: `အကူအညီများ`,
  faq: `FAQ`,
  amount: `ပမာဏ`,
  extra: `အပို`,
  deposit_bonus: `အပ်ငွေအပိုဆု`,
  do_not_participate: `ပရိုမိုးရှင်းတွေမှာ မပါဝင်ပါနဲ့။`,
  payment_problem: `ငွေပေးချေမှု ပြဿနာ`,
  click_for_help: `အကူအညီအတွက် နှိပ်ပါ။`,
  deposit_notes: `အပ်ငွေမှတ်စုများ`,
  available_balance: `လက်ကျန်ငွေ ရရှိနိုင်`,
  withdraw: `ငွေထုတ်ပါ။`,
  withdrawal_notes: `ငွေထုတ်မှတ်စုများ`,
  withdrawal_note1: `လက်ရှိငွေထုတ်အရေအတွက်`,
  withdrawal_note1_1: `vip`,
  withdrawal_note2: `ငွေထုတ်ကြေး-`,
  min: `အနည်းဆုံး-`,
  max: `အများဆုံး-`,
  notifications: `အသိပေးချက်များ`,
  invalid_x: `မမှန်ကန်ပါ {{x}}`,
  captcha_not_match: `Captcha သည် မကိုက်ညီပါ။`,
  invalid_x_must_be_atleast_y_characters: `မမှန်ကန်သော {{x}}၊ အနည်းဆုံး စာလုံး {{y}} ဖြစ်ရမည်။`,
  x_is_required: `{{x}} လိုအပ်သည်။`,
  phone_number: 'ဖုန်းနံပါတ်',
  phone_num_x_max: `ဖုန်းနံပါတ် အများဆုံး {{x}} ဂဏန်းများ`,
  email_sent: `အီးမေးလ်ပို့ပြီး၊ သင့်အီးမေးလ်ကို စစ်ဆေးပါ။`,
  enter_valid_email: `ကျေးဇူးပြု၍ တရားဝင်အီးမေးလ်ကို ထည့်ပါ။`,
  confirm: `အတည်ပြုပါ။`,
  password_atleast_6_characters: `စကားဝှက်သည် အနည်းဆုံး အက္ခရာ 6 လုံး ရှိရမည်။`,
  password_reset_success: `စကားဝှက်ပြန်လည်သတ်မှတ်ခြင်း အောင်မြင်ပါသည်။`,
  new_password: `စကားဝှက်အသစ်`,
  bank: 'ဘဏ်',
  bank_transfer: `ဘဏ်ငွေလွှဲခြင်း။`,
  bank_name: `ဘဏ်အမည်`,
  account_name: `အကောင့်အမည်`,
  account_number: `အကောင့်နံပါတ်`,
  drop_or_select: `ဖိုင်ကိုချပါ သို့မဟုတ် ရွေးပါ။`,
  drop_files_here: `ဖိုင်များကို ဤနေရာတွင် ချလိုက်ပါ။`,
  transfer_to: `သို့ လွှဲပြောင်းပါ။`,
  upload_receipt: `ပြေစာတင်ပါ။`,
  select_bonus: `Bonus ကိုရွေးချယ်ပါ။`,
  no_promotion: `Promotion မရှိပါ။`,
  please_upload_receipt: `ပြေစာ အပ်လုဒ်လုပ်ပါ။`,
  bank_account: `ဘဏ်အကောင့်`,
  add_account: `အကောင့်ထည့်ပါ။`,
  account_holder_name: `အကောင့်ကိုင်ဆောင်သူအမည်`,
  name_of_the_account_holder: `အကောင့်ကိုင်ဆောင်သူအမည်`,
  fill_in_bank_account: `ဘဏ်အကောင့်ဖြည့်ပါ။`,
  withdraw_information: `အချက်အလက်များ ထုတ်ယူပါ။`,
  note: `မှတ်ချက်`,
  amount_withdrawn: `ထုတ်ယူသည့်ပမာဏ`,
  amount_no_exceed_balance: `ထုတ်ယူသည့်ပမာဏသည် ရရှိနိုင်သောလက်ကျန်ပမာဏထက် မကျော်လွန်သင့်ပါ။`,
  minimum_withdraw_amount_is_x: `အနည်းဆုံး ငွေထုတ်ပမာဏမှာ {{x}} ဖြစ်သည်`,
  transaction_history: `ငွေလွှဲမှတ်တမ်း`,
  date_from: `ရက်စွဲမှ`,
  date_to: `Date To`,
  account_name_must_be_4: `အကောင့်အမည်သည် အရှည် 4 ထက်ကြီးရပါမည်။`,
  account_number_must_be_x: `အကောင့်နံပါတ်သည် အရှည် 10-14 ဖြစ်ရပါမည်။`,
  minimum_length_is_x: `အနိမ့်ဆုံးအရှည်မှာ {{x}}`,
  maximum_length_is_x: `အများဆုံးအရှည်မှာ {{x}}`,
  payment_method: `ငွေပေးချေစနစ်`,
  mode: `မုဒ်`,
  no_bank_account: `သင့်တွင် ထုတ်ယူရန် မည်သည့်အကောင့်မှ မရှိသေးပါ၊ ယခုထည့်ပါ။`,
  please_select_bank: `ဘဏ်အကောင့်တစ်ခုကို ရွေးပါ။`,

  bet_history: `အလောင်းအစားသမိုင်း`,
  game_provider: `ဂိမ်းပံ့ပိုးသူ`,
  bet_count: `လောင်းကြေးအရေအတွက်`,
  win_loss: `နိုင်/အရှုံး`,
  rebate: `ပြန်အမ်းငွေ`,
  turnover: `လည်ပတ်မှု`,
  game_category: `ဂိမ်းအမျိုးအစား`,

  you_have_x_unread_messages: `သင့်တွင် မဖတ်ရသေးသော စာတို {{x}} စောင်ရှိသည်။`,
  deposit_amount_between_x_and_y: `အပ်ငွေပမာဏသည် {{x}} နှင့် {{y}} ကြားရှိရမည်`,
  minimum_amount_is_x_y: `အနည်းဆုံးပမာဏမှာ {{x}}{{y}}`,

  // all
  all: `အားလုံး`,
  title: `ခေါင်းစဥ်`,
  date: `ရက်စွဲ`,
  type: `ရိုက်ပါ။`,
  status: `အဆင့်အတန်း`,
  remark: `မှတ်ချက်`,
  category: `အမျိုးအစား`,
  please_select_x: `ကျေးဇူးပြု၍ {{x}} ကို ရွေးပါ`,
  save: `သိမ်းဆည်းပါ။`,

  change_password: `စကားဝှက်ကိုပြောင်းရန်`,
  current_password: `လက်ရှိစကားဝှက်`,
  confirm_password: `စကားဝှက်အသစ်ကို အတည်ပြုပါ။`,
  submit: `တင်ပြပါ။`,
  password_changed: `စကားဝှက်ပြောင်းထားသည်။`,
  x_must_be_y_characters: `{{x}} သည် အနည်းဆုံး အက္ခရာ {{y}} လုံး ဖြစ်ရမည်။`,
  reset_password: `လျှို့ဝှတ်နံပါတ်အားမူလအတိုင်းပြန်လုပ်သည်`,
  clear: `ရှင်းလင်းသော`,
  search: `ရှာရန်`,
  new: `အသစ်`,
  logout: `ထွက်လိုက်ပါ။`,
  choose_date: `ရက်စွဲကို ရွေးပါ။`,
  select_both_date: `Date From နှင့် To နှစ်ခုလုံးကို ရွေးပါ။`,
  from: `ထံမှ`,
  to: `ရန်`,
  apply: `လျှောက်ထားပါ။`,
  promotions: `ဈေးရောင်း`,
  learn_more: `ပိုမိုသိရှိရန်`,
  password_doesnt_match: `စကားဝှက်နှင့် မကိုက်ညီပါ။`,
  cancel: `မလုပ်တော့`,
  confirm_to_pay: `ပေးချေရန် အတည်ပြုပါ။`,
  deposit_amount: `အပ်ငွေပမာဏ`,
  loading_please_wait: `တင်နေသည်၊ ခဏစောင့်ပါ။`,
  account: `အကောင့်`,
  profile: `ကိုယ်ရေးအကျဉ်း`,
  bank_list: `ဘဏ်စာရင်း`,
  in_maintenance: `Maintenance မှာ`,
  birthday: `မွေးနေ့`,
  birthday_bonus: `အပိုဆုတစ်ခုအတွက် သင့်မွေးနေ့ကို ထည့်ပါ။ 🎉🎁`,
  referral_code: `ရည်ညွှန်းကုဒ်`,
  referral: `ရည်ညွှန်းသည်။`,
  my_referral: `ကျွန်ုပ်၏ ရည်ညွှန်းချက်`,
  my_claim: `ကျွန်ုပ်၏ တောင်းဆိုချက်`,
  claim_profit: `အမြတ်ငွေကို တောင်းဆိုပါ။`,
  available_profit: `ရရှိနိုင်သောအမြတ်`,
  claimed_profit: `တောင်းဆိုထားသောအမြတ်`,
  all_time_profit: `အချိန်တိုင်းအမြတ်`,
  referral_note_on_x: `သင့်သူငယ်ချင်းများ {{x}} တွင် ကစားသည့်အခါတိုင်း အမြတ်ရယူပါ`,
  tier: `အဆင့်`,
  commission: `ကော်မရှင်`,
  referred_member: `ရည်ညွှန်းအဖွဲ့ဝင်`,
  profit_earned: `အမြတ်များ`,
  account_information: `အကောင့်အချက်အလက်`,
  invite_friends: `သူငယ်ချင်းများကိုဖိတ်ကြားသည်`,
  how_it_work: `ဘယ်လိုအလုပ်လုပ်လဲ?`,
  invite_note: `သင့်သူငယ်ချင်းသည် သင့်ရည်ညွှန်းကုဒ်ကို အသုံးပြု၍ အကောင့်ဖွင့်သည့်အခါ သီးသန့် ညွှန်းဆိုခွင့်အပိုဆုများ ရယူလိုက်ပါ။`,
  share_via: `တဆင့်မျှဝေပါ။`,
  referral_link: `ရည်ညွှန်းလင့်ခ်`,
  total_profit: `စုစုပေါင်းအမြတ်`,
  copied: `ကူးယူသည်။`,
  nothing_to_claim: `ဘာမှ တောင်းဆိုစရာ မရှိပါဘူး။`,
  claim_history: `တိုင်ကြားမှုမှတ်တမ်း`,
  downline: `ငါ့ဒေါင်းလိုင်း`,
  last_x_digit_bank_ref_number: `Bank Ref နံပါတ်၏ နောက်ဆုံးဂဏန်း {{x}}`,
  bank_ref_number_validation_x: `Bank Ref Number လိုအပ်ပြီး အက္ခရာအရှည် {{x}} ဖြစ်ရပါမည်။`,
  sort: 'မျိုးတူစု',

  refer_and_reward: `ကိုးကား & ဆုလာဘ်`,
  linked_successfully_x: `{{x}} နှင့် အောင်မြင်စွာ ချိတ်ဆက်ထားသည်။ သင့်အကောင့်ကို ပထမဆုံးအကြိမ် စာရင်းသွင်းပါ။`,
  more_x_of_y: `နောက်ထပ် {{x}}/{{y}}`,
  default: `ပုံသေ`,
  promotion: `ပရိုမိုးရှင်း`,
  daily_mission: 'နေ့စဉ်မစ်ရှင်',
  agent: 'အေးဂျင့်',
  contact_us: 'ကြှနျုပျတို့ကိုဆကျသှယျရနျ',
  download: 'ဒေါင်းလုဒ်လုပ်ပါ။',
  cash_claim: 'ငွေသားတောင်းခံခြင်း။',
  demo: 'ဒီမို',
  favorites: 'အကြိုက်ဆုံးများ',
  popular: 'လူကြိုက်များသည်။',
  claim_bonus: `အပိုဆုများကို တောင်းဆိုပါ။`,
  insufficient_balance: `လက်ကျန်ငွေ မလုံလောက်ခြင်း။`,
  currently_displaying: '{{z}} မှ ဂိမ်း {{x}} {{y}} ဂိမ်းများကို ပြသနေသည်',
  all_x: '{{x}} အားလုံး',
  no_results: 'ရလဒ်များမတွေ့ပါ။',
  search_games: 'ဂိမ်းများကို ရှာဖွေပါ။',
  please_scan_using_your: `သင့်အားအသုံးပြု၍ စကင်န်ဖတ်ပါ။`,
  vip_level: `VIP အဆင့်`,
  bonus_direct: `ဘောနပ်စ်တိုက်ရိုက်`,
  rebate_level: `ပြန်အမ်းငွေအဆင့်`,
  vip_header: `အထိုင်စက်များ၊ ဘုတ်နှင့် ကတ်ဂိမ်းများ၊ ငါးဖမ်းခြင်း၊ ဗီဒီယိုများ၊ အားကစားနှင့် ထီဂိမ်းများတွင် S24 တွင် လောင်းကြေးများအားလုံး အပြီးအပိုင် စုဆောင်းသွားပါမည်။ မှန်ကန်သော အလောင်းအစားများ = အမြဲတမ်း ၀င်ငွေနှင့် ငွေကြေး ကံကြမ္မာ။`,
  vip_example: `
  Example 1: Members who reach 1 Million in accumulated deposits, reach VIP Level 2, receive a bonus of R$210,and Rebate 3.50%, When they reach 6 Million in accumulated deposit, they reach VIP Level 3 and receive a bonus of R$800, with a Rebate 4%`,
  activity_rules: `III. Activity Rules:`,
  activity_rules_1: `1. It is necessary for all bonuses to have x3 Winover to request the Withdrawal`,
  activity_rules_2: `2. Please claim the Rebate Bonus within the deadline, if you happen to be late, it will be considered abandonment;`,
  activity_rules_3: `3. Members' participation in activities is automatically counted by the system, if there is any objection, the result of S24's investigation will prevail;`,
  activity_rules_4: `4. If you forget your member Account/Password, please contact "24-hour Online Customer Service" to help you recover your account information;`,
  activity_rules_5: `5. S24 reserves the right to change, stop or cancel this promotion at any time.`,
  activity_rules_6: `6. Do not save free game , Buy free game`,
  activity_rules_7: `7. Can't combine with any bonus`,
  activity_rules_8: `8. Maximum Withdraw per Deposit is R$100,000`,
  member: `အဖွဲ့ဝင်`,
  return_home: `အိမ်ပြန်ပါ။`,
  more: `နောက်ထပ်`,
  earn: `ဝင်ငွေ`,
  deposit_method: `အပ်ငွေနည်းလမ်း`,
  next: `နောက်တစ်ခု`,
  my_account: `ကြှနျုပျအကောငျ့`,
  level: `အဆင့်`,
  providers: `ပံ့ပိုးပေးသည်။`,
  home: `အိမ်`,
  member_center: `အဖွဲ့ဝင်စင်တာ`,
  adjustment: `များတော့`,
  no_balance: `Balance မရှိပါ။`,
  withdraw_amount: `ငွေပမာဏ`,
  lucky_draw: `ကံစမ်းမဲ`,
  services: `ဝန်ဆောင်မှုများ`,
  fast_transaction: `ငွေလွှဲမှု မြန်ဆန်ခြင်း။`,
  fast_transaction_desc: `ကျွန်ုပ်တို့သည် အပ်ငွေချန်နယ်များစွာ၊ အလိုအလျောက် လွှဲပြောင်းခြင်း-အခမဲ့ ပိုက်ဆံအိတ်၊ ရိုးရှင်းသော widhtdrawal လုပ်ငန်းစဉ်နှင့် အလွန်လျင်မြန်သော ငွေသွင်းခြင်း၊ ငွေထုတ်ခြင်းနှင့် လွှဲပြောင်းသည့်အချိန်များကို ရရှိစေရန် ပံ့ပိုးပေးပါသည်။ သီးသန့်ကွန်ရက် ပိုမိုကောင်းမွန်အောင်ပြုလုပ်ခြင်းနည်းပညာသည် သာလွန်ကောင်းမွန်သောဂိမ်းအတွေ့အကြုံကိုပေးစွမ်းရန် ကွန်ရက် latency ကိုလျှော့ချပေးသည်။`,
  safety_guaranteed: `လုံခြုံမှုအာမခံပါသည်။`,
  safety_guaranteed_desc: `သင့်ဒေတာ၏ လျှို့ဝှက်ရေးနှင့် လုံခြုံရေးကို သေချာစေရန် ကျွန်ုပ်တို့သည် ပြင်းထန်သောစမ်းသပ်မှုများအပြင် 128-Bit လျှို့ဝှက်ရေးနည်းပညာကို အသုံးပြုရန်အတွက် နိုင်ငံတကာတွင်ကျော်ကြားသော အင်တာနက်လုံခြုံရေးကိရိယာများကို ကျွန်ုပ်တို့အသုံးပြုပါသည်။ သင်၏ကိုယ်ရေးကိုယ်တာအချက်အလက်များကို မည်သည့်အခါမျှ ထုတ်ဖော်မည် သို့မဟုတ် ပြင်ပအဖွဲ့အစည်းသို့ ရောင်းချမည်မဟုတ်ပါ။ S24၊ လိုင်စင်ရ အွန်လိုင်းဖျော်ဖြေရေးပလက်ဖောင်း`,
  game_options: `ဂိမ်းရွေးချယ်မှုများ`,
  game_options_desc: `ဘောလုံး၊ ဘတ်စကက်ဘော၊ e-sport၊ တင်းနစ်၊ စနူကာနှင့် အခြားများစွာသော စိတ်လှုပ်ရှားဖွယ်ရာ အားကစားပွဲများ အားလုံးကို အချိန်နှင့်တပြေးညီ ယူဆောင်လာပါသည်။ တိုက်ရိုက်ကာစီနို၊ ထီ၊ ဖဲချပ်၊ ငါးဖမ်းခြင်းနှင့် စလော့များကဲ့သို့သော ရွေးချယ်ရန် အခြားဂိမ်းများလည်း ရှိပါသည်။`,
  multi_platform: `ပလပ်ဖောင်းအစုံ`,
  multi_platform_desc: `ထိပ်တန်းနည်းပညာဖြင့် S24 သည် လွတ်လပ်ပြီး ပြီးပြည့်စုံသော အသုံးချပရိုဂရမ်တစ်ခုကို တီထွင်ခဲ့သည်။ ယခု သင်သည် ဝဘ်၊ H5၊ iOS နှင့် Android ပေါင်းစပ်ထားသော အက်ပ်ကို ခံစားနိုင်ပြီ၊ ဖျော်ဖြေမှုနှင့် အလောင်းအစားများကို အချိန်မရွေး နေရာမရွေး ရှာဖွေနိုင်မည်ဖြစ်သည်။ 24/7 ဖောက်သည်ဝန်ဆောင်မှုပေးသူများသည် အာရုံစိုက်ပြီး အကျိုးရှိစွာ ဝန်ဆောင်မှုပေးကာ အကူအညီပေးသည်။`,
  hot_games: `ဟော့ဂိမ်းများ`,
  sports: `အားကစား`,
  casino: `ကာစီနို`,
  slots: `အထိုင်များ`,
  fishing: `တံငါ`,
  cards: `ကတ်များ`,
  games: `ဂိမ်းများ`,
  terms_n_conditions: `စည်းကမ်းသတ်မှတ်ချက်များ`,
  claim_now: `ယခုတောင်းဆိုပါ။`,
  the_activity_x_in: `လုပ်ဆောင်ချက် {{x}} တွင်`,
  starts: `ကုန်ဆုံးရက်`,
  ends: `ကုန်ဆုံးသည်။`,
  list_of_winners: `အနိုင်ရသူများစာရင်း`,
  days: `နေ့ရက်များ`,
  hours: `နာရီ`,
  minutes: `မိနစ်များ`,
  seconds: `စက္ကန့်`,
  please_login: `ကျေးဇူးပြု၍ ဝင်ပါ။`,
  promo_concluded: `ပရိုမိုးရှင်းပြီးပါပြီ။`,
  bonus_claimed: `အပိုဆုများကို တောင်းဆိုထားသည်။`,
  can_only_claim: `{{x}} မှ {{y}} Malaysia Time အကြား ဘောနပ်စ်ကို တောင်းဆိုနိုင်သည်။`,
  total_amount_received: `စုစုပေါင်းလက်ခံရရှိသည့်ပမာဏ`,
  deposit_fee: `အပ်နှံကြေး`,
  get: `သွင်း`,
  press_get_code: 'SMS မှတဆင့် သင်ကြားရေးကုဒ်ရယူရန် GET CODE ခလုတ်ကို နှိပ်ပါ',
  otp_sent: 'သင်ကြားရေးကုဒ် ပို့ပြီးပါပြီ! ဆက်လက်လုပ်ဆောင်ရန်အတွက် အောက်တွင် သင်ကြားရေးကုဒ်ကို ထည့်ပါ',
  get_code: 'ကုဒ်ရယူပါ',
  verify: 'အတည်ပြုပါ',
  verification_code: 'သင်ကြားရေးကုဒ်',
  otp_not_found: 'OTP လုပ်ဆောင်ချက် ရှာမတွေ့ပါ!',
  network_error: 'ကွန်ရက်အမှား',
  we_have_sent_otp: 'သင့်ဖုန်းနံပါတ်သို့ OTP ပို့ပြီးပါပြီ',
  your_otp_expire: 'သင်၏ OTP သည် သက်တမ်းကုန်ဆုံးမည်',
  didnt_receive: 'မက်ဆေ့ခ်ျ မရရသေးဘူးလား?',
  send_again: 'ထပ်ပို့ပါ',
  resend_again: 'ထပ်ပို့ပါ',
  invalid_verification: 'အတည်ပြုကုဒ်မှားနေသည်!',
  otp_expired: 'OTP သက်တမ်းကုန်ဆုံးသည်၊ ကျေးဇူးပြု၍ သင်၏ဖုန်းနံပါတ်ကို ထပ်မံရိုက်ထည့်ပြီး ထပ်ကြိုးစားပါ!',
  otp_expired_redirect: 'OTP သက်တမ်းကုန်ဆုံးသည်! login သို့ ပြန်ညွှန်းနေသည်',
  captcha_check_failed: 'Captcha စစ်ဆေးမှု မအောင်မြင်ပါ',
  verify_phonenumber: 'ဖုန်းနံပါတ်ကို အတည်ပြုပါ',
  send_otp: 'OTP ပို့ပါ',
  success_verification:
    'သင်၏ ဖုန်းနံပါတ် အတည်ပြုပြီးပါပြီ! ကျေးဇူးပြု၍ စာရင်းသွင်းခြင်းကိုပြီးမြောက်ရန် အောက်ဖော်ပြပါဖောင်ကို ဖြည့်ပါ',
    deposit_from_bank: 'ဘဏ်မှငွေသွင်းခြင်း။',
};

export default my;
