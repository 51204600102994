import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';

import {
  getAngPauPromotion,
  getGameCategsAndCompanies,
  getSiteSettings,
  getUserMemberBalance,
} from '../../redux/slices/lookup';

// redux
import { useDispatch, useSelector } from '../../redux/store';
import useAuth from '../../hooks/useAuth';

import Image from '../../components/Image';

import useLocales from '../../hooks/useLocales';

import { usePageVisibility } from '../../hooks/usePageVisibility';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingBottom: '1rem',
  backgroundColor: '#f9f9f9',
}));

// ----------------------------------------------------------------------

const BrandLayout = () => {
  const { isAuthenticated } = useAuth();

  const { collapseClick, isCollapse, onToggleCollapse } = useCollapseDrawer();

  const { categSlug, companyId } = useParams();

  useEffect(() => {
    if (isAuthenticated) getUserMemberBalance();
    getGameCategsAndCompanies();
    getSiteSettings();
  }, []);

  return (
    <Box>
      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    </Box>
  );
};

export default BrandLayout;
